import { Injectable } from '@angular/core';
import { GenericRequestService } from './generic-request.service';
import { ProcesswebService } from './processweb.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProviderConfigurationService extends GenericRequestService {

  constructor(protected processWebService: ProcesswebService, protected router: Router) {
    super(processWebService, router);
  }

  getDefaultProducts(body: any) {
    return this.genericRequest(body, '63');
  }

  getBanks(body: any) {
    return this.genericRequest(body, '/in-table');
  }

  getAccounts(body: any) {
    return this.genericRequest(body, '/in-table');
  }

  getAllProviders(body: any) {
    return this.genericRequest(body, '/in-table');
  }

  getNameAgency(body: any) {
    return this.genericRequest(body, '/in-table')
  }

  getConfigurationAgency(body: any) {
    return this.genericRequest(body, '/creation-agency');
  }

  getLastConfigurationProvider(body: any) {
    return this.genericRequest(body, '/supplier-creation-commission');
  }

  saveConfigurationProvider(body: any) {
    return this.genericRequest(body, '/supplier-creation-commission');
  }
  saveConfigurationProviderDocuments(body: any) {
    return this.genericRequest(body, '/supplier-creation-commission');
  }

  getProviderProductConfiguration(body: any) {
    return this.genericRequest(body, '63');
  }

  saveProviderProductConfiguration(body: any) {
    return this.genericRequest(body, '63');
  }

  getAllComitionsProvider(body: any) {
    return this.genericRequest(body, '/in-table');
  }
   
  protected genericRequest(body: any, endpoint: string) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken()
      //   .subscribe((token: any) => {
      const request: string = JSON.stringify(body);
      this.processWebService.execProcessEnd(request, endpoint, 'post')
        .subscribe((res: any) => {
          
          // tslint:disable-next-line:triple-equals
          if (res.idresponse === 1) {
            resp(res.dataresponse); 
          } else {
            reject(res.response);
          }
        });
      // });
    });
  }
  protected genericRequestAll(body: any, operation?: string) {
    return new Promise((resp, reject) => {
      this.processWebService.getToken()
        .subscribe((token: any) => {
          const request: string = JSON.stringify(body);
          this.processWebService.execProcessInTransactionalDb(request, (operation || '16'), token.access_token)
            .subscribe((res: any) => {
              // tslint:disable-next-line:triple-equals
              if (res.status == true) {
                resp(res.response);
              } else {
                reject(res.response);
              }
            });
        }
        );
    });
  }
}
